.App {
  width: 100vw;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 132px;
  padding-bottom: 1%;
  padding-left: 3%;
  padding-right: 3%;
}

.Content {
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 50px;
  padding-top: 60px;
  padding-left: 3%;
  padding-right: 3%;
}

@media (max-width: 599px) {
  .App {
    padding-top: 82px;
  }

  .Content {
    padding-top: 20px;
  }
}

.priceTable {
  border-radius: 25px;
  max-width: 700px;
}

.headerText {
  color: #00086e;
}

.contactText {
  color: #00086e;
}

/* .mailLink {
  color: #00086e;
  text-decoration: none;
}

.mailLink:hover {
  text-decoration: wavy;
  color: purple;
} */

.mailLink {
  background: linear-gradient(to bottom, var(--mainColor) 0%, var(--mainColor) 100%);
	background-position: 0 100%;
	background-repeat: repeat-x;
	background-size: 3px 3px;
  color: 00086e;
  text-decoration: none;
}

.mailLink:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .9s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar {
  display: block;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0.44, rgb(122, 153, 217)),
      color-stop(0.72, rgb(73, 125, 189)),
      color-stop(0.86, rgb(28, 58, 148)));
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 50px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 50px;
}